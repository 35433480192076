export const environment = {
  production: true,
  APP_ENV: 'staging',
  APP_NAME: 'Sina Test',
  APP_DOMAIN: 'Sina.leren.com.ar',
  APP_API_BASE: 'https://apisina.leren.com.ar/',
  APP_WEB_BASE: 'https://sina.leren.com.ar/',
  APP_ADMIN_BASE: 'https://sina-admin.leren.com.ar/',
  APP_MAILING_BASE: 'https://mailing.leren.com.ar/',
  APP_MAILING_SUBSCRIBE: false,
  APP_SATISFACCION_BASE: 'https://sina-satisfaccion.leren.com.ar/',
};
